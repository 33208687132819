import { IHub } from "../../common/types/hub";
import { setConnectedHubStateRoutine } from "./hub-routines";

const initHub = { connected: false } as IHub;

export const hubReducer = (state = initHub, action: any) => {
    switch (action.type) {
        case setConnectedHubStateRoutine.TRIGGER: {
            return {
                connected: action.payload.connected
            }
        }
        default: {
            return state;
        }
    }
}