import axios, { AxiosError } from 'axios';
import { store } from '../..';
import { env } from '../../env';
import { setErrorRoutine } from '../../store/error/routines';

const client = axios.create({
    headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'Content-Encoding': 'identity'
    }
})

client.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token');
        if (token) config.headers.Authorization = `Bearer ${token}`;
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
)

export const setErrorToState = (error: any) => {
    store.dispatch(setErrorRoutine(error));
}

export const handleError = async (error: AxiosError) => {

    if (tryProcessErrorMessage(error)) {
        return;
    }

    const accessToken = localStorage.getItem("token");
    const refreshToken = localStorage.getItem("refreshToken");

    try {
        const refreshResp = await client.post(`${env.apiUrl}/api/v1/auth/refresh`, { accessToken, refreshToken })
        if (refreshResp.status === 200) {
            localStorage.setItem('token', refreshResp.data.accessToken)
            localStorage.setItem('refreshToken', refreshResp.data.refreshToken)
        }

        error.config.headers.Authorization = `Bearer ${refreshResp.data.accessToken}`

        const rest = await client.request(error.config);

        return rest.data;
    }
    catch (e) {
        localStorage.clear()
        redirectToLogin();
    }

    return null;
}

const tryProcessErrorMessage = (error: AxiosError): boolean => {
    const { response: resp } = error;

    // Should Handle Token Refresh
    if (error.config && resp && resp.status === 401) {
        return false;
    }

    if(!resp) {
        return false;
    }

    setErrorToState(resp.data || error.message);
    redirectToLogin();

    return true;
}

const redirectToLogin = () => {
    if(window.location.href.includes('/login')) {
        return;
    }

    window.location.href = '/login';
}

export { client };