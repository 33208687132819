import { all, fork } from 'redux-saga/effects';
import { gamesSaga } from './games/games.saga';
import { pollsSaga } from './polls/saga';
import { clientsSaga } from '../store/clients/saga';
import loginSaga from './user/saga';
import { gameSaga } from './game/game.saga';
import { insightsSaga } from './insights/insights.saga';

export default function* rootSaga() {
    yield all([fork(gamesSaga), fork(clientsSaga), fork(pollsSaga), fork(loginSaga), fork(gameSaga), fork(insightsSaga)]);
}