import { takeLatest, all, call, put } from "redux-saga/effects";
import { addClientRoutine, getClientsRoutine, removeClientRoutine, updateClientRoutine } from "./routines";
import { addClientRequest, getAllClientsRequest, removeClientRequest, updateClientRequest } from "../../services/api/clients.service";

function* getAllClients() {
    try {
        const clients = yield call(getAllClientsRequest);
        yield put(getClientsRoutine.success(clients));
    } catch (err) {
        yield put(getClientsRoutine.failure(err.message));
    }
}

function* removeClient(action: any) {
    try {
        const client = yield call(removeClientRequest, action.payload);
        yield put(removeClientRoutine.success(client));
    } catch (err) {
        yield put(removeClientRoutine.failure(err.message));
    }
}

function* updateClient(action: any) {
    const { client, id } = action.payload;

    try {
        const updatedClient = yield call(updateClientRequest, { client, id } as any);
        yield put(updateClientRoutine.success(updatedClient));
    } catch (err) {
        yield put(updateClientRoutine.failure(err.message));
    }
}

function* addClient(action: any) {
    try {
        const createdClient = yield call(addClientRequest, action.payload);
        yield put(addClientRoutine.success(createdClient));
    } catch (err) {
        yield put (addClientRoutine.failure(err.message));
    }
}

export function* clientsSaga() {
    yield all([
        takeLatest(getClientsRoutine, getAllClients),
        takeLatest(removeClientRoutine, removeClient),
        takeLatest(addClientRoutine, addClient),
        takeLatest(updateClientRoutine, updateClient)
    ]);
}