const tryParseJSON = (textualRepresentation: string) => {
	let insight = ''
	try {
		insight = JSON.parse(textualRepresentation)
		if(!insight) {
			return '';
		}
	}
	catch(e) {
		return '';
	}
	return insight;
}

const parseLocalJson = (propName: string, local: any) => {
	return tryParseJSON(propName)[local];
}

export { tryParseJSON, parseLocalJson };