import { all, call, put, takeLatest } from "redux-saga/effects";
import { history } from "../../utils/history";
import { getCurrentUserRequest, loginRequest } from "../../services/api/auth";
import { getCurrentUserRoutine, loginRoutine } from "./routines";

function* login(action: any) {
    try {
        const callResponse = yield call(loginRequest, action.payload);

        if( !callResponse) {
            return;
        }

        yield put(loginRoutine.success())
        yield put(getCurrentUserRoutine.trigger())

        history.push("/cms/chat");
    } catch (err) {
        yield put(loginRoutine.failure(err.message));
    }
}

function* getCurrentUser() {
    try {
        const user = yield call(getCurrentUserRequest);

        if (!user) {
            throw Error('User is null')
        }

        yield put(getCurrentUserRoutine.success(user));
    } catch (err) {
        yield put(getCurrentUserRoutine.failure(err.message))
    }
}

export default function* loginSaga() {
    yield all([
        takeLatest(loginRoutine, login),
        takeLatest(getCurrentUserRoutine, getCurrentUser),
    ]);
}