import React from 'react';
import { Formik, FormikProps } from 'formik';
import { Button, Form, Input, Tab } from 'semantic-ui-react';
import { IInfoProps } from '../../../../../../../common/types/client-modal-models';
import ImageUploader from 'react-images-upload';

export interface IInfoTabProps {
    mainInfo: IInfoProps;
    goToNextTab: (formPage: number, mainInfo: IInfoProps) => void;
    onImageDrop: (files: File[]) => void;
    picture: string | null;
}

const InfoTab: React.FC<IInfoTabProps> = (props) => {
    const { mainInfo, goToNextTab, onImageDrop, picture } = props;

    return (
        <Tab.Pane>
            <Formik
                key={'infoTab'}
                initialValues={mainInfo}
                onSubmit={(values, { setSubmitting }) => {
                    goToNextTab(1, values);
                    setSubmitting(false);
                }}
                render={({ values, handleSubmit, handleChange }: FormikProps<IInfoProps>) => (
                    <Form size="large">
                        <Form.Group widths='equal'>
                            <Form.Field inline>
                                <Input
                                    label={'Client Name'}
                                    inline={true}
                                    size="mini" 
                                    fluid
                                    key={'name'}
                                    onChange={handleChange}
                                    type="text"
                                    name="name"
                                    placeholder="Client Name"
                                    value={values.name} />
                            </Form.Field>
                            <Form.Field inline>
                                <Input 
                                    label={'Contact'}
                                    inline={true}
                                    size="mini" 
                                    fluid
                                    key={'contact'}
                                    onChange={handleChange}
                                    type="text"
                                    name="contact"
                                    placeholder="Contact"
                                    value={values.contact} />
                            </Form.Field>
                        </Form.Group>
                        <Form.Field inline>
                            <Input 
                                type="text"
                                label={'Address'}
                                name="address"
                                placeholder="Address"
                                value={values.address} 
                                fluid
                                inline={true}
                                size="mini" 
                                key={'licenses'}
                                onChange={handleChange}
                                    />
                        </Form.Field>
                        <Form.Field inline>
                            <Input 
                                type="email"
                                label={'Email'}
                                name="email"
                                placeholder="Email address"
                                value={values.email} 
                                fluid
                                inline={true}
                                size="mini" 
                                key={'email'}
                                onChange={handleChange}
                                    />
                        </Form.Field>
                        <div style={{
                            display: 'block',
                            margin: '0 0 .28571429rem 0',
                            color: 'rgba(0,0,0,.87)',
                            fontSize: '0.92857143em',
                            fontWeight: 700,
                            textTransform: 'none'
                        }}>
                            Upload client logo
                            </div>
                        {
                            picture &&
                            <div className='logo-container'>
                                <img src={picture} alt='Logo' />
                            </div>
                        }
                        <ImageUploader
                            withIcon={true}
                            buttonText='Choose images'
                            onChange={onImageDrop}
                            imgExtension={['.jpg', '.png', '.jpeg']}
                            maxFileSize={5242880} />
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button type='button' onClick={() => handleSubmit()}>Next</Button>
                        </div>
                    </Form>
                )} />
        </Tab.Pane>
    )
}

export { InfoTab }