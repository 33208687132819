import { client, handleError } from "./client";
import { env } from "../../env";
import { IPollVote } from "../../common/types/poll";

export const getPollByInsightIdRequest = async (insightId: number) => {
    try {
        const response = await client.get(env.apiUrl + "/api/v1/polls/getByInsight" + insightId);
        return response.data || [];
    }
    catch (error) {
        console.log(error);
        return handleError(error);
    }
}

export const getAllPollsRequest = async () => {
    try {
        const response = await client.get(env.apiUrl + "/api/v1/polls");
        return response.data || [];
    }
    catch (error) {
        console.log(error);
        return handleError(error);
    }
}

export const voteRequest = async (pollVote: IPollVote) => {
    try {
        const response = await client.post(env.apiUrl + '/api/v1/polls/vote', pollVote);
        return response.data || [];
    }
    catch (error) {
        console.log(error);
        return handleError(error);
    }
}