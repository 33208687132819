import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Modal, Tab } from 'semantic-ui-react';
import { compressAndGetBase64String } from '../../../../../common/helpers/image-helpers';
import { Client } from '../../../../../common/types/client';
import { IClientAdminProps, IInfoProps } from '../../../../../common/types/client-modal-models';
import { addClientRoutine, updateClientRoutine } from '../../../../../store/clients/routines';
import { AdminTab } from './Tabs/AdminTab/AdminTab';
import { InfoTab } from './Tabs/InfoTab/InfoTab';

interface IClientModalProps {
    modalOpened: boolean,
    addClient: (client: Client) => void;
    updateClient: (data: { client: Client, id: number }) => void;
    closeModal: () => void;
    isUpdate: boolean;
    infoTab: IInfoProps;
    adminTab: IClientAdminProps;
}

const ClientModal: React.FC<IClientModalProps> = (props) => {
    const { infoTab, adminTab, isUpdate, updateClient, addClient, modalOpened, closeModal } = props;
    const [page, setPage] = useState(0);
    const [mainInfo, setMainInfo] = useState(infoTab);
    const [picture, setPicture] = useState('');
    const [adminInfo, setAdminInfo] = useState(adminTab);

    const goToAdminTab = (formPage: number, values: IInfoProps) => {
        setPage(formPage);
        setMainInfo({
            ...mainInfo,
            ...values
        });
    }

    const goToPreviousTab = (formPage: number) => {
        setPage(formPage);
    }

    const onDrop = async (files: File[]) => {
        const base64 = await compressAndGetBase64String(files);
        setPicture(base64 as string);
    }

    const onConfirm = (formPage: number, values: IClientAdminProps) => {
        setAdminInfo(values);

        const model = {
            id: mainInfo?.id,
            contact: mainInfo.contact,
            email: mainInfo.contact,
            name: mainInfo.name,
            picture: picture,
            disabled: mainInfo.disabled,
            clientAdmin: {
                id: values.id,
                email: values.adminEmail,
                password: values.password,
                role: values.selectedRole,
                group: mainInfo.name,
                username: values.adminEmail
            },
            users: [],
            isDeleted: false
        }

        if (isUpdate) {
            updateClient({
                client: model as any,
                id: model.id as number
            });
            return;
        }

        addClient(model as any);

        closeModal();
    }

    const tabs = [
        {
            menuItem: 'Main Info',
            render: () => <InfoTab
                goToNextTab={goToAdminTab}
                mainInfo={mainInfo}
                onImageDrop={onDrop}
                picture={picture}

            />,
        },
        {
            menuItem: 'Admin',
            render: () => <AdminTab
                adminInfo={adminInfo}
                goToNextTab={onConfirm}
                goToPreviousTab={goToPreviousTab}
            />
        }]

    return (
        <Modal
            open={modalOpened}
            onClose={closeModal}>
            <Modal.Header>Create new group</Modal.Header>
            <Modal.Content>
                <Modal.Description>
                    <Tab panes={tabs} activeIndex={page} />
                </Modal.Description>
            </Modal.Content>
        </Modal>
    )
}

const mapDispatchToProps = {
    addClient: addClientRoutine,
    updateClient: updateClientRoutine
}

export default connect(null, mapDispatchToProps)(ClientModal);