import { takeLatest, all, call, put } from "redux-saga/effects";
import { startPushinsRequest, stopPushinsRequest } from "../../services/api/insights.service";


import { startPushingRoutine, stopPushingRoutine } from "./insights.routines";

function* startPushing(action: any) {
    try {
        yield call(startPushinsRequest);
        yield put(startPushingRoutine.success());
    } catch (err) {
        yield put(startPushingRoutine.failure(err.message));
    }
}

function* stopPushing(action: any) {
    try {
        yield call(stopPushinsRequest);
        yield put(stopPushingRoutine.success());
    } catch (err) {
        yield put(stopPushingRoutine.failure(err.message));
    }
}

export function* insightsSaga() {
    yield all([
        takeLatest(startPushingRoutine, startPushing),
        takeLatest(stopPushingRoutine, stopPushing),
    ]);
}