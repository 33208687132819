import React from "react";
import { connect } from "react-redux";

import { parseLocalJson } from "../../../common/helpers/parsing-insight-text";
import { IPoll } from "../../../common/types/poll";

import './stacked-bar.scss';

interface IStackedBarProps {
    Poll: IPoll;
    local: any;
}

const colors = [
    'red',
    'orange',
    'yellow',
    'olive',
    'green',
    'teal',
    'blue',
    'violet',
    'purple',
    'pink',
    'brown',
    'grey',
];

const HorizontalStackedBarComponent: React.FC<IStackedBarProps> = (props) => {

    const { Poll, local } = props;

    const language = local ? local : 'en';

    const totalVotes = Poll.options.reduce((a: any, b: any) => a + b.votes.length, 0)

    const countOfOptions = Poll.options.length;
    const countOfZeroVotedOptions = Poll.options.filter(opt => opt.votes.length === 0).length;

    var widthOfPart: number;

    if (countOfZeroVotedOptions != 0 && countOfZeroVotedOptions != countOfOptions) {
        widthOfPart = 100 / (countOfOptions + countOfZeroVotedOptions);
    }
    else {
        widthOfPart = 100 / countOfOptions;
    }

    return (<div className="bar-chart">
        {Poll && Poll.options.map((pollOption, index) => {

            const percentages = totalVotes
                ? (pollOption.votes.length / totalVotes) * 100
                : 0;

            const width = percentages
                ? percentages * widthOfPart
                : widthOfPart;
           
            return (
                <div key={pollOption.id}
                    style={{ width: `${width}%` }}>
                    <div className="bar-data">
                        <p className="inner-text">{percentages}%</p>
                    </div>
                    <div
                        className="bar-data"
                        style={{ background: colors[index] }}>
                        <p className="inner-text">{pollOption.votes.length}</p>
                    </div>
                    <div className="bar-data">
                        {parseLocalJson(pollOption.text, language)}
                    </div>
                </div>
            )
        })}
    </div>);
}

const HorizontalStackedBar = connect(null, null)(HorizontalStackedBarComponent);
export { HorizontalStackedBar };