import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Input, Button } from 'semantic-ui-react';
import { IUser } from '../../common/types/user';
import { IState } from '../../store/intialState';
import { loginRoutine } from '../../store/user/routines';

import { history } from '../../utils/history';

import './login.scss';

interface ICredentials {
    email: string;
    password: string;
}
interface ILoginProps {
    login: (credentials: ICredentials) => void;
    user: IUser;
    error: string;
}

const Login: React.FC<ILoginProps> = (props) => {
    const { login, user, error } = props;
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');

    useEffect(() => {
        if (localStorage.getItem('token')) {
            history.push('/games');
        }
    }, [])

    const onLoginSubmit = () => {
        login({email, password});
    }

    return (
        <div className='login-page_container'>
            <Input
                type="text"
                placeholder='Email'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
            />
            <Input
                type="password"
                placeholder='Password'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
            />
            <Button onClick={onLoginSubmit}>Login</Button>
            <span className='exception'>{error}</span>
        </div>
    )
}

const mapStateToProps = (state: IState) => {
    return {
        user: state.user!,
        error: state.error
    }
}

const mapDispatchToProps = {
    login: loginRoutine,
}   

export default connect(mapStateToProps, mapDispatchToProps)(Login);