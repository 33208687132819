import React from 'react';
import { Form, Radio } from 'semantic-ui-react';
import { parseLocalJson } from '../../common/helpers/parsing-insight-text';
import { IPoll, IPollOption } from '../../common/types/poll';

import "./poll-options.scss";

interface IPollOptionProps {
    poll: IPoll,
    option: IPollOption,
    isVoted: boolean,
    votedOption: IPollOption | undefined,
    local: any,
    color: string,

    handleVote: (value: number) => void;
}

const PollOptions: React.FC<IPollOptionProps> = ({ poll, option, isVoted, local, votedOption, color, handleVote }) => {

    if (isVoted) {
        const totalVotes = poll.options.reduce((a: any, b: any) => a + b.votes.length, 0);
        const percentages = `${(option.votes.length / totalVotes) * 100}%`;

        return (
            <div>
                <div
                    className="poll-option"
                    data-label={percentages}>
                    <div
                        className="progress"
                        style={{ width: percentages, backgroundColor: color }} />
                    <div className="option-text">
                        {parseLocalJson(option.text, local)}
                        {votedOption && option.id === votedOption.id &&
                            <span className="selected-option">
                                &#10004;
                            </span>}
                    </div>
                </div>
            </div>
        );
    }

    return (
        <Form.Field className="radio-option" key={option.id}>
            <Radio
                className="radio-option_content"
                label={parseLocalJson(option.text, local)}
                name={`poll${poll.id}`}
                value={option.id}
                onChange={(_e, val) => handleVote(Number(val.value))}
                key={option.id} />
        </Form.Field>
    )
}

export { PollOptions };