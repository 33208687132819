import { env } from "../../env";
import { client, handleError, setErrorToState } from "./client";

export const loginRequest = async (authModel: any) => {
    try {
        const response = await client.post(env.apiUrl + "/api/v1/auth/login", authModel);
        if (response.data.accessToken) {
            localStorage.setItem('token', response.data.accessToken)
        }
       
        if (response.data.refreshToken) {
            localStorage.setItem('refreshToken', response.data.refreshToken)
        }

        setErrorToState('');
        return response.data || [];
    }
    catch (error) {
        console.log(error);
        return handleError(error);
    }
}


export const getCurrentUserRequest = async () => {
    try {
        const response = await client.get(`${env.apiUrl}/api/v1/auth/current`);

        return response.data || null;
    }
    catch (error) {
        console.log(error);
        // localStorage.clear();
        return handleError(error);
    }
}