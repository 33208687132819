import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import { history } from '../../../utils/history';

import { getGameRoutine } from "../../../store/game/game.routines";
import { getAllPollsRoutine } from "../../../store/polls/routines";

import { IGame } from "../../../common/types/game";

import { InsightPollTable } from "../InsightPollTable/InsightPollTable";

import './game-managment.scss';
import { Container } from "semantic-ui-react";

export interface IGameManagmentComponentProps {
    game: IGame;
    local: any;
    getGame: (gameId: number) => void;
}

const GameManagmentComponent: React.FC<IGameManagmentComponentProps> = (props) => {
    const {
        game,
        local,
        getGame
    } = props;

    const { id } = useParams<{ id: string }>();

    useEffect(() => {
        getGame(Number(id));
    }, []);


    const backToGameList = () => {
        history.push("/cms/games");
    }

    return (
        <Container>
            {game &&
                <div className="game-container">
                    <div className="title">
                        <span className="team">{game.team1Name} </span>
                vs
                <span className="team"> {game.team2Name}</span>
                    </div>
                    <div>
                        Event id: {game.eventId}
                    </div>

                    <InsightPollTable
                        local={local}
                        game={game}
                        showLive={true}
                        showPreGame={true}
                        showOnlyWithPolls={false} />
                </div>}
            {!game && (
                <div>Loading...</div>
            )}
        </Container>
    );
}

const mapStateToProps = (state: any) => {
    return {
        game: state.game,
        polls: state.polls.source,
        userId: state.user!.id
    }
};

const mapDispatchToProps = {
    getGame: getGameRoutine,
    loadAllPolls: getAllPollsRoutine,
}

const GameManagment = connect(mapStateToProps, mapDispatchToProps)(GameManagmentComponent);
export { GameManagment };
