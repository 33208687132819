
import { initialState } from "../intialState";
import { addLiveInsightRoutine } from "./insights.routines";

export const insightsReducer = (state = initialState.insights, action: any) => {
    switch (action.type) {
        case addLiveInsightRoutine.TRIGGER: {
            return [...state, action.payload];
        }
        default: {
            return state;
        }
    }
}
