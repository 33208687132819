import React from 'react';
import { Icon } from 'semantic-ui-react';
import { parseLocalJson } from '../../common/helpers/parsing-insight-text';
import { IInsight } from '../../common/types/insights';
import { ITheme } from '../../common/types/theme';

import './insight.scss';

export interface IPlayerInsightProps {
    insight: IInsight;
    local: any;
    className: string;
    collapsed: boolean;
    theme: ITheme;
}

const PlayerInsight = (props: IPlayerInsightProps) => {

    const { insight, local, collapsed, className } = props;

    const icon = collapsed
        ? <Icon name="chart bar" circular className="no-margin chart-icon-area" />
        : <Icon name="close" circular className="no-margin close-icon-area" />;

    return (
        <div className={className}>
            <div className="ui field insight">
                <span className="insight-text">{parseLocalJson(insight.textualRepresentation, local)}</span>
                {icon}
            </div>
        </div>
    )
}

export { PlayerInsight }