import * as React from 'react';
import { Card } from 'semantic-ui-react';
import { tryParseJSON } from '../../common/helpers/parsing-insight-text';
import { IInsight } from '../../common/types/insights';

import './live-insight.scss'

interface ILiveInsightProps {
    insight: IInsight;
    local: any;
}

export const LiveInsight: React.FC<ILiveInsightProps> = (props) => {
    const { insight, local } = props;

    return (
        <Card className="chat_insight">
            <div className="chat_insight-container-centered">
                <div className="circle">
                    <p>Insight</p>
                </div>
                <p>{tryParseJSON(insight.textualRepresentation)[local]}</p>
            </div>
        </Card>
    )
}