import React from 'react';
import { Button, Header, Modal } from 'semantic-ui-react';


export interface IClientRemoveModalProps {
    objectId: number;
    modalOpened: boolean;
    closeModal: () => void;
    deleteAction: (id: number) => void;
    type: string;
}

const ClientRemoveModal: React.FC<IClientRemoveModalProps> = (props) => {
    const { modalOpened, closeModal, deleteAction, type, objectId } = props;

    return (
        <Modal
            size='small'
            open={modalOpened}
            onClose={closeModal}>
            <Header icon='delete' content='Delete user' />
            <Modal.Content>
                <p>
                    Are you sure you want to remove the {type}?
                </p>
            </Modal.Content>
            <Modal.Actions>
                <Button color='red' onClick={closeModal}>
                    No
            </Button>
                <Button color='black' onClick={() => {
                    deleteAction(objectId);
                    closeModal();
                }}>
                    Yes
            </Button>
            </Modal.Actions>
        </Modal>
    );
}

export { ClientRemoveModal }