import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { createStore, applyMiddleware, compose } from "redux";
import createSagaMidddelware from "redux-saga";
import { Provider } from 'react-redux';

import { env } from './env';

import { createSocketMiddleware } from './utils/sockets/socket.middleware';
import { history } from './utils/history';

import rootSaga from './store/rootSaga';
import reducers from './store/rootReducer';
import App from './components/Container/Container';

import 'semantic-ui-css/semantic.min.css';
import './index.css';

const sagaMiddelware = createSagaMidddelware();
const socketMiddleware = createSocketMiddleware();
const middleware = [socketMiddleware, sagaMiddelware];

const composeEnhancer = env.envType === 'Local' 
    ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ 
    : compose;

export const store = createStore(
  reducers,
  composeEnhancer(applyMiddleware(...middleware))
);

sagaMiddelware.run(rootSaga);

const Application = (props: { store: any}) => {
    return (
        <Provider store={props.store}>
            <Router history={history}>
                <App/>
            </Router>
        </Provider>
    );
}

ReactDOM.render(<Application store={store} />, document.getElementById('root'));


