import { createRoutine } from "redux-saga-routines";

export const joinToRoomRoutine = createRoutine('CHAT/JOIN_TO_ROOM');

export const leaveRoomRoutine = createRoutine('CHAT/LEFT_ROOM')

export const clearChatRoutine = createRoutine('CHAT/CLEAR_CHAT');

export const sendMessageRoutine = createRoutine('CHAT/SEND_MESSAGE');

export const receiveMessageRoutine = createRoutine('CHAT/RECEIVED_MESSAGE');

export const leaveEventRoutine = createRoutine('EVENT/LEFT_EVENT');