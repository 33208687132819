import { IMessage } from "../../common/types/message";
import { joinToRoomRoutine, clearChatRoutine, receiveMessageRoutine } from "./room-routines";

const initState = { userId: 0, eventId: 0, username: '', participant: null, roomId: 0, messages: [], roomNumber: 0 };

export const roomReducer = (state = initState, action: any) => {
    switch (action.type) {
        case joinToRoomRoutine.TRIGGER: {
            return {
                ...state,
                messages: []
            }
        }
        case joinToRoomRoutine.SUCCESS: {            
            return {
                ...state,
                eventId: action.payload.eventId,
                roomId: action.payload.roomId,
                roomNumber: action.payload.roomNumber,
                participant: action.payload.participant,
                messages: [...action.payload.messages],
            }
        }
        case clearChatRoutine.TRIGGER: {
            return {
                ...state,
                messages: []
            }
        }
        case receiveMessageRoutine.TRIGGER: {
            const limit = 100;
            const lastIndex = state.messages.length;
            const startIndex = lastIndex - limit + 1;
            const lastMessages = startIndex < 0 ? state.messages : state.messages.slice(startIndex)
            const newMessage = action.payload as IMessage;
            return {
                ...state,
                messages: [...lastMessages, newMessage]
            }
        }
        default: {
            return state;
        }
    }
}