import React, { useState, Fragment, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Picker } from 'emoji-mart';
import { Input } from 'semantic-ui-react';

import { history } from '../../utils/history';

import { clearLiveInsightsRoutine } from '../../store/games/games.routines';
import { clearChatRoutine, joinToRoomRoutine, leaveRoomRoutine, sendMessageRoutine } from '../../store/rooms/room-routines';

import Filter from 'bad-words';

import { ITheme } from '../../common/types/theme';

import { IInsight } from '../../common/types/insights';
import { LiveInsight } from '../live-insight/live-insight';
import { Message } from '../message/Message';
import { IRoom } from '../../common/types/room';
import { INewMessage } from '../../common/types/new-message';
import { IMessage } from '../../common/types/message';
import { IGame } from '../../common/types/game';

import 'emoji-mart/css/emoji-mart.css'
import './chat.scss';

interface IChatProps {
    room: IRoom,
    liveInsights: IInsight[];
    local: any;
    theme: ITheme;
    game: IGame;
    username: string;
    userId: number;
    colors: Map<number, string>;

    sendMessage: (message: INewMessage) => void;
    clear: (eventId: number) => void;
    clearLiveInsights: (eventId: number) => void;
    joinRoom: (joinCommand: { eventId: number, userId: number, username: string }) => void;    
}

const ChatComponent: React.FC<IChatProps> = (props) => {
    const [message, setMessage] = useState('');
    const [maxMessageLength] = useState<number>(200);
    const messagesEndRefDiv = useRef<HTMLDivElement>(null);
    const [wordFilter] = useState(new Filter());
    const [showEmojiPicker, setShowEmojiPicker] = useState(false);
    const [joined, setJoined] = useState(false);
    const {
        colors,
        userId,
        username,
        liveInsights,
        theme,
        game,
        room,
        local,
        joinRoom,
        sendMessage,
        clear,
        clearLiveInsights,        
    } = props;

    useEffect(() => {
        messagesEndRefDiv?.current?.scrollIntoView({ behavior: "smooth", block: 'nearest' });

        if (!joined) {
            joinRoom({ eventId: game.eventId, userId: userId, username: username });
            setJoined(true);
        }
    }, [messagesEndRefDiv, room.messages]);

    if (!game.eventId || game.eventId === 0) {
        return <div style={{ color: theme.containerTextColor, padding: '1em' }}>Select game for chat...</div>;
    }

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        event.persist();

        if (event.key === 'Enter' && message !== '') {
            const filteredMessage = wordFilter.clean(message);
            setMessage('');
            sendMessage({ //TODO: don't wait for response from server on sending own message, just add them to messages.
                message: filteredMessage,
                participantId: room.participant.id,
            } as INewMessage);
        }
    };

    const handleMessageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const inputText = e.target.value;

        if (inputText.length > maxMessageLength) {
            return;
        }

        setMessage(inputText);
    };

    const addEmoji = (e: any) => {
        setMessage(message + e.native);
    };

    const endMatch = () => {
        clear(room.eventId);
        history.push('/games');
    };

    const getColorForParticipant = (participantId: number): string => {

        if (!colors.has(participantId)) {
            const randomColor = Math.floor(Math.random() * 16777215).toString(16);
            colors.set(participantId, "#" + randomColor);
        }

        return colors.get(participantId) as string;
    };

    return (
        <div className='chat-page_container'>
            <div className="chat-container">
                {
                    username && (
                        <Fragment>
                            <div className="room-number">
                                Room #{room.roomNumber}
                            </div>
                            <div className='ui card chat_messages-block' style={{ backgroundColor: theme.chatBgColor, color: theme.chatTxtColor }}>
                                <div>
                                    {room.messages.length
                                        ? (<div>
                                            {room.messages.map((mess: IMessage) =>
                                                <Message
                                                    key={mess.id}
                                                    message={mess}
                                                    isOwn={mess.participantId === room.participant.id}
                                                    color={getColorForParticipant(mess.participantId)} />)}
                                            <div style={{ float: "left", clear: "both" }} ref={messagesEndRefDiv} />
                                        </div>)
                                        : <div className="empty-chat-message">Let's start this chat! Tell us your opinion!</div>}
                                </div>
                            </div>
                            <div>
                                <Input
                                    className='chat_send-input'
                                    type="text"
                                    placeholder='Send a message'
                                    value={message}
                                    onPaste={(e: any) => { e.preventDefault(); return false; }}
                                    onChange={handleMessageChange}
                                    onKeyDown={handleKeyDown}
                                />
                                <div className='emoji-trigger' onClick={() => setShowEmojiPicker(!showEmojiPicker)}>
                                    <span role='img' aria-label="basic_icon">🙂</span>
                                </div>
                                {showEmojiPicker && (<Picker onSelect={addEmoji} />)}
                            </div>
                        </Fragment>
                    )
                }
                <div
                    className="match-info-container"
                    style={{ backgroundColor: theme.chatBgColor, color: theme.chatTxtColor }}>
                    <span>Chat for {game.team1Name} vs {game.team2Name}</span>
                    <button onClick={endMatch}>End match</button>
                </div>
            </div>
            {
                liveInsights.length ? (
                    <div className="chat-insights_container">
                        <div>
                            <span>Live insights list: </span>
                            <button onClick={() => clearLiveInsights(liveInsights.pop()?.gameId as number)}>Clear</button>
                        </div>
                        {
                            liveInsights.map((x: any) => (
                                <LiveInsight insight={x} local={local} />
                            ))
                        }
                    </div>
                ) : <Fragment />
            }
        </div>
    );
}

const mapStateToProps = (state: any) => ({
    game: state.game,
    room: state.room,
    username: state.user!.username,
    userId: state.user!.id,
    liveInsights: state.games.source.filter((x: any) => x.isLive)
});

const mapDispatchToProps = {
    sendMessage: sendMessageRoutine,
    clear: clearChatRoutine,
    joinRoom: joinToRoomRoutine,
    clearLiveInsights: clearLiveInsightsRoutine,    
};

const Chat = connect(mapStateToProps, mapDispatchToProps)(ChatComponent);
export { Chat };
