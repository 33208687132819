import * as React from 'react';

interface ILanguageSelector {
    changeLocal: (local: string) => void;
}

const LanguageSelector: React.FC<ILanguageSelector> = ({ changeLocal }) => (
    <div>
        <select onChange={(event) => changeLocal(event.target.value)}>
            <option key={'en'} value="en">English</option>
            <option key={'ru'} value="ru">Russian</option>
        </select>
    </div>
)

export { LanguageSelector };