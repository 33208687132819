import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import ReactHlsPlayer from 'react-hls-player';

import { Button, Menu, Tab } from 'semantic-ui-react';

import { history } from '../../utils/history';

import { getGameRoutine } from '../../store/game/game.routines';
import { getAllPollsRoutine } from '../../store/polls/routines';
import { leaveEventRoutine } from '../../store/rooms/room-routines';
import { connectSocketRoutine } from '../../store/shared.routines';
import { startPushingRoutine, stopPushingRoutine } from '../../store/insights/insights.routines';

import { ITheme, BetfredTheme, Bet365Theme, LadbrokesTheme } from '../../common/types/theme';
import { IGame } from '../../common/types/game';
import { IHub } from '../../common/types/hub';
import { IPoll } from '../../common/types/poll';

import { Chat } from '../Chat/Chat';
import { InsightsPage } from '../InsightsPage/InsightsPage';
import { LanguageSelector } from '../language-selector/LanguageSelector';

import './game-widget.scss';

const themes: ITheme[] = [
    new BetfredTheme(), new Bet365Theme(), new LadbrokesTheme()
];

export interface IGameWidgetProps {
    game: IGame;
    polls: IPoll[];
    hub: IHub;

    loadAllPolls: () => void;
    getGame: (gameId: number) => void;
    connectToHub: () => void;
    leaveEvent: (eventId: number) => void;
    startPushing: () => void;
    stopPushing: () => void;
}

const GameWidgetComponent: React.FC<IGameWidgetProps> = (props) => {
    const {
        game,
        hub,
        polls,
        loadAllPolls,
        getGame,
        connectToHub,
        leaveEvent,
        startPushing,
        stopPushing
    } = props;

    const [local, setLocal] = useState('en');
    const [sorting, setSorting] = useState('asc');
    const [type, setType] = useState('teams');
    const [theme, setTheme] = useState<ITheme>(themes[0]);

    const { id } = useParams<{ id: string }>();

    useEffect(() => {
        getGame(Number(id));
        loadAllPolls();

        if (!hub.connected) {
            connectToHub();
        }

        return function cleanup() {
            leaveEvent(Number(id));
            stopPushing();
        };
    }, []);


    const backToGameList = () => {
        history.push("/games");
    }

    const menuSetup = { color: theme.tabColor, inverted: true, tabular: false, attached: true };

    const panes = [
        {
            menuItem: { key: 'chat', icon: 'users', content: 'Live chat' },
            render: () =>
                <Tab.Pane style={{ backgroundColor: theme.insightsBgColor }}>
                    <Chat
                        local={local}
                        theme={theme}
                        colors={new Map<number, string>()} />
                </Tab.Pane>
        },
        {
            menuItem: (
                <Menu.Item key="insights">
                    Insights/Polls
                </Menu.Item>
            ),
            render: () =>
                <Tab.Pane style={{ backgroundColor: theme.insightsBgColor }} active>
                    <InsightsPage
                        type={type}
                        local={local}
                        sorting={sorting}
                        polls={polls}
                        activeInsightId={-1}
                        theme={theme}
                        eventId={game.eventId}
                    />
                </Tab.Pane>
        },
    ];

    return (
        <div>
            { game &&
                <div className='app_container'>
                    <div
                        className="header"
                        style={{ backgroundColor: theme.headerColor }}>
                        <div
                            className="header_logo"
                            style={{ background: theme.background, backgroundImage: theme.backgroundImage, backgroundColor: theme.headerColor }}                            >
                            <div className="theme">
                                <select onChange={event => setTheme(themes[parseInt(event.target.value)])}>
                                    {
                                        themes.map((o, index) => (<option key={index} value={index}>{o.headerTitle}</option>))
                                    }
                                </select>
                            </div>
                        </div>
                    </div>
                    <div
                        className="game-title"
                        style={{ backgroundColor: theme.titleColor }}>
                        <span className="team">{game.team1Name}&nbsp;</span>
                                          vs
                        <span className="team">&nbsp;{game.team2Name}</span>
                        {game.eventId === 21026 &&
                            (<div className="stream-buttons">
                                <Button
                                    style={{ backgroundColor: theme.titleColor }}
                                    onClick={startPushing}>
                                    Start
                                </Button>
                                <Button
                                    style={{ backgroundColor: theme.titleColor }}
                                    onClick={stopPushing}
                                >
                                    Stop
                                    </Button>
                            </div>)}
                    </div>
                    <ReactHlsPlayer
                        className="player"
                        url="https://t-stream.igamemedia.com/hls/s11.m3u8"
                        autoplay={false}
                        controls={true}
                        placeholder="stream"
                        key="stream-player"
                    />
                    <Tab
                        className="tabs"
                        menu={menuSetup}
                        panes={panes}
                    //renderActiveOnly={false}
                    />
                    <div className='app_container__header'>
                        <LanguageSelector changeLocal={setLocal} />
                    </div>
                    <div className="filter-container">
                        {/* <Filters
                            changeLocal={setLocal}
                            changeSorting={setSorting}
                            changeInsightsType={setType}
                        /> */}
                        <button className="back-btn" onClick={backToGameList}>Back to game list</button>
                    </div>
                </div>
            }
            {!game && (
                <div>Loading...</div>
            )}
        </div>
    )
}

const mapStateToProps = (state: any) => {
    return {
        game: state.game,
        polls: state.polls.source,
        hub: state.hub
    }
};

const mapDispatchToProps = {
    getGame: getGameRoutine,
    loadAllPolls: getAllPollsRoutine,
    connectToHub: connectSocketRoutine,
    leaveEvent: leaveEventRoutine,
    startPushing: startPushingRoutine,
    stopPushing: stopPushingRoutine,
}

const GameWidget = connect(mapStateToProps, mapDispatchToProps)(GameWidgetComponent);
export { GameWidget };