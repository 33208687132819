import React, { useEffect, useState } from "react";
import { Button, Icon } from "semantic-ui-react";
import { connect } from "react-redux";

import { history } from '../../../utils/history';
import { setSelectedGameRoutine } from "../../../store/game/game.routines";
import { getAllGamesRoutine } from "../../../store/games/games.routines";
import { connectSocketRoutine } from "../../../store/shared.routines";
import { logoutRoutine } from "../../../store/user/routines";

import { IHub } from "../../../common/types/hub";
import { IGame } from "../../../common/types/game";

import { Game } from "../GameComponent/GameComponent";

import './games.scss';

export interface IGamesProps {
    games: IGame[];
    hub: IHub;

    onSelectGame: (game: IGame) => void;
    loadAllGames: () => void;
    connectToHub: () => void;
    setGame: (game: IGame) => void;
    logoutTrigger: () => void;
}

const GamesComponent: React.FC<IGamesProps> = (props) => {
    const { games, hub, setGame, loadAllGames, connectToHub, logoutTrigger, onSelectGame } = props;
    const gameList = games.map((x: any) => ({ label: `${x.team1Name} VS ${x.team2Name} [${x.eventId}]`, value: x.eventId }));

    useEffect(() => {
        loadAllGames();
        if (!hub.connected) {
            connectToHub();
        }
    }, []);

    const logout = () => {
        localStorage.clear();
        logoutTrigger();
        history.push('/login');
    };

    const selectGame = (game: IGame) => {

        setGame(game);

        onSelectGame(game);
    };

    return (
        <div>
            <div className="games">
                {gameList.length &&//TODO: add loader 
                    <div>
                        <h2>Available games:</h2>
                        <div className="game">
                            {games.map((game: IGame) =>
                                (<Game
                                    game={game}
                                    key={game.eventId}
                                    onClick={selectGame} />)
                            )}
                        </div>
                    </div>
                }
                {!gameList.length &&
                    <h4>No available games for now</h4>}

                <Button onClick={logout}>
                    <Icon name='sign out' />
                    <span>Log out</span>
                </Button>
                <Button onClick={() => history.push('/cms/chat')}>
                    <Icon name='sign out' />
                    <span>To CMS</span>
                </Button>
            </div>
        </div>
    );
}

const mapStateToProps = (state: any) => {
    return {
        games: state.games.source,
        hub: state.hub,
    }
};

const mapDispatchToProps = {
    loadAllGames: getAllGamesRoutine,
    connectToHub: connectSocketRoutine,
    setGame: setSelectedGameRoutine,
    logoutTrigger: logoutRoutine,
};

const Games = connect(mapStateToProps, mapDispatchToProps)(GamesComponent);
export { Games };