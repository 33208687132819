import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Card } from 'semantic-ui-react';

import { IState } from '../../../store/intialState';
import { Client } from '../../../common/types/client';

import { ClientCard } from './ClientCard/ClientCard';
import { ClientRemoveModal } from './Modals/ClientRemoveModal/ClientRemoveModal';
import ClientModal from './Modals/ClientModal/ClientModal';
import { IClientAdminProps, IInfoProps } from '../../../common/types/client-modal-models';
import { getClientsRoutine, removeClientRoutine } from '../../../store/clients/routines';

import './index.scss';

interface IClientPageProps {
    clients: {
        source: Client[],
        isLoading: boolean,
        error: any
    },
    getClients: () => void;
    removeClient: (id: number) => void;
}

const ClientPage: React.FC<IClientPageProps> = ( {clients, getClients, removeClient }) => {
    const [clientToRemove, setClientToRemove] = useState(0);
    const [clientToUpdate, setClientToUpdate] = useState(null as any);
    const [createModalOpened, setCreateModalOpened] = useState(false);

    useEffect(() => {
        getClients();
    }, [])

    const openClientRemoveModal = (id: number) => {
        setClientToRemove(id);
    }

    const openClientUpdateModal = (item: Client) => {
        setClientToUpdate(item);
    }

    const defaultAdminProps: IClientAdminProps = {
        adminEmail: '',
        id: 0,
        password: '',
        selectedRole: 0
    }

    const defaultInfoProps: IInfoProps = {
        address: '',
        contact: '',
        disabled: false,
        email: '',
        name: '',
        picture: ''
    }

    const closeModal = () => {
        setCreateModalOpened(false);
    }

    const closeUpdateModal = () => {
        setClientToUpdate(null);
    }

    const renderCards = () => {
        return clients.source.map(item =>  {
            return <ClientCard 
                item={item}
                openRemoveModal={openClientRemoveModal}
                openUpdateModal={openClientUpdateModal}
                 />
        })
    }

    return (
        <div className='client-page__container'>
            <Fragment>  
                {
                    clients.source && clients.source.length > 0 ? renderCards() : 'loading'
                }
            </Fragment>
            <Card onClick={() => setCreateModalOpened(true)}>
                <div className="group-management_add-card">
                    <span>+</span>
                </div>
            </Card>
            <ClientModal 
                adminTab={defaultAdminProps}
                infoTab={defaultInfoProps}
                isUpdate={false}
                closeModal={() => closeModal()}
                modalOpened={createModalOpened}
            />
            {
                clientToUpdate && 
                ( 
                    <ClientModal 
                        adminTab={{
                            adminEmail: clientToUpdate.clientAdmin.email,
                            id: clientToUpdate.clientAdmin.id,
                            selectedRole: clientToUpdate.clientAdmin.selectedRole,
                            password: ''
                        }}
                        infoTab={{
                            id: clientToUpdate?.id,
                            address: clientToUpdate.address,
                            contact: clientToUpdate.contact,
                            email: clientToUpdate.email,
                            name: clientToUpdate.name,
                            picture: clientToUpdate.picture,
                            disabled: clientToUpdate.disabled
                        }}
                        isUpdate={true}
                        closeModal={() => closeUpdateModal()}
                        modalOpened={clientToUpdate != null}
                    />
                )
            }
            <ClientRemoveModal
                modalOpened={clientToRemove != 0}
                closeModal={() => setClientToRemove(0)}
                objectId={clientToRemove}
                type='Client'
                deleteAction={() => removeClient(clientToRemove)}
            />
        </div>   
    )
}

const mapStateToProps = (state: IState) => {
    return {
        clients: state.clients
    }
}

const mapDispatchToProps = {
    getClients: getClientsRoutine,
    removeClient: removeClientRoutine
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientPage);