import { getAllPollsRoutine, voteRoutine } from "./routines";

export const getPollsReducer = (state = { isLoading: false, source: [], error: '' }, action: any) => {
    switch (action.type) {
        case getAllPollsRoutine.TRIGGER: {
            return {
                ...state,
                isLoading: true
            }
        }
        case getAllPollsRoutine.SUCCESS: {
            return {
                isLoading: false,
                source: action.payload
            }
        }
        case getAllPollsRoutine.FAILURE: {
            return {
                ...state,
                error: action.payload
            }
        }
        case voteRoutine.SUCCESS: {
            return {
                ...state,
                isLoading: false,
                source: [...state.source, action.payload]
            }
        }
        default: {
            return state;
        }
    }
}
