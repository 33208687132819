import React from 'react';
import { Icon } from 'semantic-ui-react';
import { parseLocalJson } from '../../common/helpers/parsing-insight-text';
import { IInsight } from '../../common/types/insights';
import { ITheme } from '../../common/types/theme';

import './insight.scss';

export interface ITeamInsightProps {
    insight: IInsight;
    local: any;
    className: string;
    collapsed: boolean;
    theme: ITheme;
}

const TeamInsight = (props: ITeamInsightProps) => {

    const { insight, local, className, collapsed, theme } = props;

    const icon = collapsed
        ? <Icon name="chart bar" size="large" circular className="no-margin insight-icon chart-icon-area" />
        : <Icon name="close" size="large" circular className="no-margin insight-icon close-icon-area" />;

    return (
        <div className={className}>
            <div
                className="ui field insight"
                style={{ backgroundColor: theme.insightColor }}>
                <span className="insight-text">{parseLocalJson(insight.textualRepresentation, local)}</span>
                {icon}
            </div>
        </div>
    )
}

export { TeamInsight }