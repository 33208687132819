import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Form } from 'semantic-ui-react';
import { IInsight } from '../../common/types/insights';
import { parseLocalJson } from '../../common/helpers/parsing-insight-text';
import { IPoll, IPollOption, IPollVote } from '../../common/types/poll';
import { ITheme } from '../../common/types/theme';
import { voteRoutine } from '../../store/polls/routines';
import { PollOptions } from '../poll-option/PollOptions';

import './poll.scss';

const colors: string[] = [
    'dodgerblue',
    'gold',
    'greenyellow',
    'olivedrab',
    'fuchsia',
    'sienna',
    'khaki',
    'cyan',
    'tomato',
    'limegreen'
];

interface IPollProps {    
    userId: number,
    insight: IInsight;
    poll: IPoll;
    isVoted: boolean;
    local: any;
    theme: ITheme;
    votedOption: IPollOption | undefined;

    vote: (pollVote: IPollVote) => void;
}

const Poll: React.FC<IPollProps> = (props) => {
    const { insight, local, theme, userId, vote } = props;

    const [poll, setPoll] = useState<IPoll>(props.poll);
    const [votedOption, setOption] = useState<IPollOption | undefined>(props.votedOption)
    const [isVoted, setVote] = useState<boolean>(props.isVoted);
    

    const handleVote = (optionId: number) => {
        const clonedOptions: IPollOption[] = poll.options.map(o => o);

        const newPollVote: IPollVote = { id: 0, pollOptionId: optionId, userId: userId };

        const option = clonedOptions.find(opt => opt.id === optionId);
        option?.votes.push(newPollVote);

        const newPoll: IPoll = { ...poll, options: clonedOptions };

        setPoll(newPoll);
        setVote(true);
        setOption(option);

        vote(newPollVote);
    };

    return (
        <Form>
            <Form.Field className="ui field poll-container">
                <div className="question">
                    {parseLocalJson(poll.question, local)}
                </div>
                {
                    poll.options.map((o, index) => (
                        <PollOptions
                            key={o.id}
                            poll={poll}
                            option={o}
                            isVoted={isVoted}
                            votedOption={votedOption}
                            local={local}
                            handleVote={handleVote}
                            color={colors[index]}
                        />))
                }
                {isVoted &&
                    <div
                        className="bet"
                        style={{ backgroundColor: theme.betColor }}>
                        <span>Is interested: {insight.isInterested.toString()} | </span>
                        <span>Group number: {insight.group} | </span>
                        <span>Insight number: {insight.insightNumber} | </span>
                    </div>
                }
            </Form.Field>
        </Form>
    );
}

const mapStateToProps = (state: any) => {
    return {        
        userId: state.user!.id
    }
};

const mapDispatchToProps = {
    vote: voteRoutine,
}

const PollComponent = connect(mapStateToProps, mapDispatchToProps)(Poll);
export { PollComponent }