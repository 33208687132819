import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Checkbox, Container, Grid, Icon, Table, TableCell } from "semantic-ui-react";
import { parseLocalJson } from "../../../common/helpers/parsing-insight-text";
import { IGame } from "../../../common/types/game";
import { IInsight } from "../../../common/types/insights";
import { IPoll } from "../../../common/types/poll";
import { getGameRoutine } from "../../../store/game/game.routines";
import { getAllPollsRoutine } from "../../../store/polls/routines";
import { LiveInsight } from "../../live-insight/live-insight";
import { HorizontalStackedBar } from "../HorizontalStackedBar/HorizontalStackedBar";

import './insight-poll-table.scss';

interface IInsightPollTableProps {
    game: IGame;
    local: any;

    showLive: boolean;
    showPreGame: boolean;
    showOnlyWithPolls: boolean;

    liveInsights: IInsight[];
    preGameInsights: IInsight[];

    livePolls: IPoll[];
    preGamePolls: IPoll[];

    loadAllPreGameInsights: () => void;
    loadAllPreGamePolls: () => void;

    loadAllLiveInsights: () => void;
    loadAllLivePolls: () => void;
}

const InsightPollTableComponent: React.FC<IInsightPollTableProps> = (props) => {

    const {
        game,
        local,
        preGameInsights,
        liveInsights,
        preGamePolls,
        livePolls,
        loadAllLiveInsights,
        loadAllLivePolls,
        loadAllPreGameInsights,
        loadAllPreGamePolls
    } = props;

    const [showLive, setShowLive] = useState(props.showLive);
    const [showPreGame, setShowPregame] = useState(props.showPreGame);
    const [showOnlyWithPolls, setShowOnlyWithPolls] = useState(props.showOnlyWithPolls);

    const language = local ? local : "en";

    const getPollsByFilters = () => {
        var pollList: IPoll[];

        if (showPreGame) {
            pollList = showLive
                ? preGamePolls.concat(livePolls)
                : preGamePolls
        }
        else if (showLive) {
            pollList = livePolls;
        }
        else {
            pollList = [];
        }

        return pollList;
    }

    const allPolls = getPollsByFilters();

    const containsPollForInsights = (insight: IInsight): boolean => {
        return allPolls.find(poll => poll.insightId == insight.id) !== undefined;
    }

    const getSortedInsightsByFilters = () => {
        var insightsList: IInsight[];

        if (showPreGame) {
            insightsList = showLive
                ? preGameInsights.concat(liveInsights)
                : preGameInsights;
        }
        else if (showLive) {
            insightsList = liveInsights;
        }
        else {
            insightsList = [];
        }

        if (showOnlyWithPolls) {
            insightsList = insightsList.filter(insight => containsPollForInsights(insight));
        }

        return insightsList.length ? insightsList.sort(ins => ins.group) : insightsList;
    };

    const allInsights = getSortedInsightsByFilters();

    useEffect(() => {
        loadAllPreGameInsights();
        loadAllPreGamePolls();
    }, []);

    const changeLiveInsights = () => {
        setShowLive(!showLive);
    };

    const changePregameInsights = () => {
        setShowPregame(!showPreGame);
    }
    const changeOnlyWithPolls = () => {
        setShowOnlyWithPolls(!showOnlyWithPolls);
    }

    const attentionTableCell = <TableCell error> <Icon name='attention' /></TableCell>;

    return (
        <Container className="content">
            <Container className="filters">
                Insights:
                <Container className="filters">
                    <Checkbox
                        className="filter"
                        toggle
                        label="Live"
                        checked={showLive}
                        onChange={changeLiveInsights} />
                    <Checkbox
                        className="filter"
                        toggle
                        label="Pregame"
                        checked={showPreGame}
                        onChange={changePregameInsights} />
                    <Checkbox
                        className="filter"
                        toggle
                        label="Only with polls"
                        checked={showOnlyWithPolls}
                        onChange={changeOnlyWithPolls} />
                </Container>
            </Container>
            <Table>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>
                            Id
                        <Icon name='sort' />
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            Content
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            Group
                            <Icon name='sort' />
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            Insight
                            <Icon name='sort' />
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            Interested
                            <Icon name='sort' />
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            Poll
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            Poll results
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {allInsights.map(insight => {
                        const pollForInsight = allPolls.find(pol => pol.insightId === insight.id) as IPoll;

                        return (<Table.Row key={insight.id}>
                            <Table.Cell>
                                {insight.id}
                            </Table.Cell>
                            <Table.Cell>
                                {parseLocalJson(insight.textualRepresentation, language)}
                            </Table.Cell>
                            <Table.Cell>
                                {insight.group}
                            </Table.Cell>
                            <Table.Cell>
                                {insight.insightNumber}
                            </Table.Cell>
                            <Table.Cell>
                                {insight.isInterested ? 'Yes' : 'No'}
                            </Table.Cell>
                            <Table.Cell>
                                {pollForInsight && parseLocalJson(pollForInsight.question, language)}
                                {!pollForInsight && attentionTableCell}
                            </Table.Cell>
                            <Table.Cell>
                                {pollForInsight &&
                                    (<div className="stack-bar">
                                        <HorizontalStackedBar Poll={pollForInsight} local={language} />
                                    </div>)}
                                {!pollForInsight && attentionTableCell}
                            </Table.Cell>
                        </Table.Row>);
                    })}
                </Table.Body>
            </Table>
        </Container >
    );
}

const MapStateToProps = (state: any) => {
    return {
        liveInsights: state.insights,
        preGameInsights: state.game.insights,
        livePolls: state,
        preGamePolls: state.polls.source,
    }
};

const MapDispatchToProps = {
    getGame: getGameRoutine,
    loadAllPolls: getAllPollsRoutine,
    loadAllPreGameInsights: getAllPollsRoutine,// TODO: set appropriate routines
    loadAllPreGamePolls: getAllPollsRoutine,
    loadAllLiveInsights: getAllPollsRoutine,
    loadAllLivePolls: getAllPollsRoutine,
};

const InsightPollTable = connect(MapStateToProps, MapDispatchToProps)(InsightPollTableComponent);
export { InsightPollTable };