import React from 'react';
import { connect } from 'react-redux';
import { Icon } from 'semantic-ui-react';

import { logoutRoutine } from '../../../store/user/routines';
import { history } from '../../../utils/history';

import './index.scss';

interface ICmsSidebarProps {
    logoutTrigger: () => void;
    user: any;
}

const CmsSidebar: React.FC<ICmsSidebarProps> = ({ children, logoutTrigger, user }) => {
    const logout = () => {
        localStorage.clear();
        logoutTrigger();
        history.push('/login');
    };

    return (
        <div className="cms__container">
            <div className="cms__sidebar">
                <div className="cms__logo"></div>
                <div className="cms__menu">
                    <div className="cms__menu-item" onClick={() => history.push('/cms/clients')}>
                        <Icon name='user' />
                        <span>Clients</span>
                    </div>
                    <div className="cms__menu-item" onClick={() => history.push('/games')}>
                        <Icon name='list' />
                        <span>List of games</span>
                    </div>
                    {/* TODO: hide polls\insights tab from users */}
                    <div className="cms__menu-item" onClick={() => history.push('/cms/games')}>
                        <Icon name='check square outline' />
                        <span>Polls\Insights</span>
                    </div>
                    <div className="cms__menu-item" onClick={logout}>
                        <Icon name='sign out' />
                        <span>Log out</span>
                    </div>
                </div>
            </div>
            <div className="cms__main">
                <div className="cms__header">
                    <div className="cms__email">
                        <Icon name='user' />
                        <span>{user.email}</span>
                    </div>
                </div>
                <div className="cms__pages">
                    {children}
                </div>
            </div>
        </div>
    )
}

const mapDispatchToProps = {
    logoutTrigger: logoutRoutine
}

const mapStateToProps = (state: any, ownProps: any) => {
    return {
        user: state.user
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CmsSidebar)