import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import ClientPage from './ClientPage/ClientPage';
import CmsSidebar from './SideBar/CmsSidebar';
import { ChatManagement } from './ChatManagement/ChatManagement';
import { Role } from '../../common/types/roles';
import PrivateRoute from '../Container/PrivateRoute';
import { Games, IGamesProps } from './Games/Games';
import { IGame } from '../../common/types/game';
import { history } from '../../utils/history';
import { GameManagment } from './GameManagment/GameManagment';

interface CmsContinaerProps {
    match: {
        path: string
    },
}

const onSelectGameCms = (game: IGame) => {
    history.push(`/cms/games/${game.eventId}`);
};

const propsGamesCms = { onSelectGame: onSelectGameCms } as IGamesProps;

const CmsContainer: React.FC<CmsContinaerProps> = ({ match }) => (
    <CmsSidebar>
        <Switch>
            <PrivateRoute
                exact
                roles={[Role.Admin, Role.ClientAdmin]}
                path={`${match.path}/clients`}
                component={ClientPage}
            />
            <PrivateRoute
                exact
                roles={[Role.Admin, Role.ClientAdmin]}
                path={`${match.path}/chat`}
                component={ChatManagement}
            />
            <PrivateRoute
                exact
                path={`${match.path}/games`}
                component={() => <Games {...propsGamesCms} />}
                roles={[Role.Admin, Role.ClientAdmin]} />
            <PrivateRoute
                path={`${match.path}/games/:id`}
                component={GameManagment}
                roles={[Role.Admin, Role.ClientAdmin, Role.User]}
            />
            <Route path='*' render={() => <Redirect to={'/cms/chat'} />} />
        </Switch>
    </CmsSidebar>
)

export { CmsContainer }