import { combineReducers } from "redux";
import { getClientsReducer } from "./clients/reducer";
import { errorHandlerReducer } from "./error/reducer";
import { gameReducer } from "./game/game.reducer";
import { hubReducer } from "./hubs/hub-reducer";
import { gamesReducer } from "./games/games.reducer";
import { getPollsReducer } from "./polls/reducer";
import { roomReducer } from "./rooms/room-reducer";
import { userReducer } from "./user/reducer";
import { insightsReducer } from "./insights/insights.reducer";

export default combineReducers(
    {
        games: gamesReducer,
        polls: getPollsReducer,
        user: userReducer,
        clients: getClientsReducer,
        error: errorHandlerReducer,
        room: roomReducer,
        game: gameReducer,
        hub: hubReducer,
        insights: insightsReducer,
    }
);