import { client, handleError } from "./client";
import { env } from "../../env";

export const getAllInsightsRequest = async () => {
    try {
        const response = await client.get(env.apiUrl + "/api/v1/insights");
        return response.data || [];
    }
    catch (error) {
        console.log(error);
        return handleError(error);
    }
}

export const startPushinsRequest = async () => {
    try {
        const response = await client.post(env.apiUrl + "/api/v1/streams/start");
        return response.data || {};
    }
    catch (error) {
        console.log(error);
        return handleError(error);
    }
}

export const stopPushinsRequest = async () => {
    try {
        const response = await client.post(env.apiUrl + "/api/v1/streams/stop");
        return response.data || {};
    }
    catch (error) {
        console.log(error);
        return handleError(error);
    }
}