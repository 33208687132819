import { takeLatest, all, call, put } from "redux-saga/effects";
import { getAllPollsRoutine, voteRoutine } from "./routines";
import { getAllPollsRequest, voteRequest } from "../../services/api/polls.service";

function* getAllPolls() {
    try {
        const insights = yield call(getAllPollsRequest);
        yield put(getAllPollsRoutine.success(insights));
    } catch (err) {
        yield put(getAllPollsRoutine.failure(err.message));
    }
}

function* vote(action: any) {
    try {
        const createdVotePoll = yield call(voteRequest, action.payload);
        yield put(voteRoutine.success(createdVotePoll));
    } catch (err) {
        yield put(voteRoutine.failure(err.message));
    }
}

export function* pollsSaga() {
    yield all([
        takeLatest(getAllPollsRoutine, getAllPolls),
        takeLatest(voteRoutine, vote),
    ]);
}