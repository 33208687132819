import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import { CmsContainer } from '../CMS/CmsContainer';
import { history } from '../../utils/history';

import { getCurrentUserRoutine } from '../../store/user/routines';
import { Role } from '../../common/types/roles';
import { Games, IGamesProps } from '../CMS/Games/Games';

import PrivateRoute from './PrivateRoute';
import { IGameWidgetProps, GameWidget } from '../GameWidget/GameWidget';
import Login from '../Login/Login';
import { IGame } from '../../common/types/game';

interface IAppProps {
    getUser: () => void;
}

const App: React.FC<IAppProps> = (props) => {
    useEffect(() => {
        if (localStorage.getItem('token')) {
            props.getUser();
        } else {
            history.push('/login');
        }
    }, []);

    const onSelectGameToWatch = (game: IGame) => {
        history.push(`/games/${game.eventId}`);
    };

    const propsGamesToWatch = { onSelectGame: onSelectGameToWatch } as IGamesProps;

    return <Switch>
        <Route exact path='/login' render={() => <Login />} />
        <PrivateRoute path="/cms" component={CmsContainer} roles={[Role.Admin, Role.ClientAdmin]} />
        <PrivateRoute
            exact
            path="/games"            
            component={() => <Games {...propsGamesToWatch} />}
            roles={[Role.Admin, Role.ClientAdmin, Role.User]} />
        <PrivateRoute
            path="/games/:id"
            component={(props: IGameWidgetProps) => <GameWidget {...props} />}
            roles={[Role.Admin, Role.ClientAdmin, Role.User]} />
        <Route render={() => <Redirect to="/login" />} />
    </Switch>
}

const mapDispatchToProps = {
    getUser: getCurrentUserRoutine
}

export default connect(null, mapDispatchToProps)(App)