import imageCompression from 'browser-image-compression';

export const toBase64 = (file: File | Blob) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});

export const compressAndGetBase64String = async (files: File[]) => {
    const options = {
        maxSizeMB: .25,
        maxWidthOrHeight: 512,
        useWebWorker: true
    }
    const file = files[files.length - 1];
    const compressedFile = await imageCompression(file, options)
    return await toBase64(compressedFile);
}
