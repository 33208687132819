import { initialState } from "../intialState";
import { getCurrentUserRoutine, logoutRoutine} from "./routines";

export const userReducer = (state = initialState.user, action: any) => {
    switch (action.type) {
        case getCurrentUserRoutine.SUCCESS: {
            return action.payload;
        }
        case getCurrentUserRoutine.FAILURE: {
            return null;
        }
        case logoutRoutine.TRIGGER: {
            return null;
        }
        default: {
            return state;
        }
    }
}