import React, { useEffect } from 'react';
import { connect } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import { IState } from '../../store/intialState';
import { getCurrentUserRoutine } from "../../store/user/routines";

const PrivateRoute = ({ component: Component, roles, ...rest }: any) => {

    const renderPriveRoute = () => {
        return (
            <Route {...rest} render={(props) => {
                const currentUser = rest.user;
                const token = localStorage.getItem('token');
                if (!currentUser && token) {
                    return 'Loading...'
                }
                else if (!currentUser && !token) {
                    return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
                }

                if (roles && roles.indexOf(currentUser.role) === -1) {
                    return <Redirect to={{ pathname: '/' }} />
                }

                return <Component {...props} />
            }} />
        )
    }

    return renderPriveRoute()
}

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);

export function mapStateToProps(state: IState) {
    return {
        user: state.user
    };
}

export function mapDispatchToProps(dispatch: any) {
    return {
        loadUser() {
            dispatch(getCurrentUserRoutine())
        }
    };
}