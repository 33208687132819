import { IInsight } from "../types/insights";

const sortAscending = (insights: IInsight[]) => {	
	return insights.sort((a: IInsight, b: IInsight) => {
		if (a.group < b.group) {
			return -1;
		}
		else if (a.group > b.group) {
			return 1;
		}
		else {
			if (a.insightNumber < b.insightNumber) {
				return -1;
			}
			else if (a.insightNumber > b.insightNumber) {
				return 1;
			}
			return 0;
		}
	});
}

const sortDescending = (insights: IInsight[]) => {	
	return insights.sort((a: IInsight, b: IInsight) => {
		if (a.group > b.group) {
			return -1;
		}
		else if (a.group < b.group) {
			return 1;
		}
		else {
			if (a.insightNumber < b.insightNumber) {
				return -1;
			}
			else if (a.insightNumber > b.insightNumber) {
				return 1;
			}
			return 0;
		}
	});
}

export { sortAscending, sortDescending }