import { connect } from "./socket.service";
import { HubConnection } from "@microsoft/signalr";
import { connectSocketRoutine } from "../../store/shared.routines";

import { joinToRoomRoutine, clearChatRoutine, receiveMessageRoutine, sendMessageRoutine, leaveRoomRoutine, leaveEventRoutine } from "../../store/rooms/room-routines";
import { setConnectedHubStateRoutine } from "../../store/hubs/hub-routines";
import { addLiveInsightRoutine } from "../../store/insights/insights.routines";

const createSocketMiddleware = () => {
    const hubConnection: HubConnection = connect();

    return (storeAPI: any) => (next: any) => (action: any) => {
        switch (action.type) {
            case connectSocketRoutine.TRIGGER:
                {
                    hubConnection.start()
                        .then(() =>
                            console.log("...Then")
                        )
                        .then(() =>
                            storeAPI.dispatch(setConnectedHubStateRoutine.trigger({ connected: true }))
                        )
                        .then(() =>
                            console.log("...Then...")
                        );

                    hubConnection.onclose((error: any = '') => {
                        console.log("Connection closed. " + error)
                    });
                    hubConnection.on("onInsight", (data) => {
                        storeAPI.dispatch(addLiveInsightRoutine.trigger(data))
                    });

                    hubConnection.on("onMessage", (data) => {
                        storeAPI.dispatch(receiveMessageRoutine.trigger(data));
                    });
                    hubConnection.on("successJoinRoom", (successJoinResult) => {
                        storeAPI.dispatch(joinToRoomRoutine.success(successJoinResult));
                    });
                    hubConnection.on("onError", (error) => {
                        alert(error);
                    });

                    return next(action);
                }
            case joinToRoomRoutine.TRIGGER: {
                hubConnection.send('joinToRoom', action.payload);
                return next(action);
            }
            case sendMessageRoutine.TRIGGER: {
                hubConnection.send('sendMessage', action.payload);
                return next(action);
            }
            case clearChatRoutine.TRIGGER: {
                hubConnection.send('clear', action.payload);
                return next(action);
            }
            case leaveRoomRoutine.TRIGGER: {
                hubConnection.send('leaveRoom', action.payload);
                return next(action);
            }
            case leaveEventRoutine.TRIGGER: {
                hubConnection.send('leaveEvent', action.payload);
                return next(action);
            }
            default: {
                return next(action)
            }
        }
    }
}

export { createSocketMiddleware }