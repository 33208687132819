import { IGame } from "../common/types/game";
import { IUser } from "../common/types/user";

export interface IState {
    game: IGame | null;
    user: IUser | null,
    insights: RequestedCollection;
    games: RequestedCollection;
    polls: RequestedCollection;
    clients: RequestedCollection;
    chat: {
        userId: string;
        roomId: string;
        messages: any[];
    },
    error: string;
}

interface RequestedCollection {
    isLoading: boolean;
    source: any[];
    error: string;
}

export const initialState = {
    error: '',
    game: null,
    user: null,
    insights: [],
    polls: {
        isLoading: false,
        source: [],
        error: ''
    },
    chat: {
        userId: '',
        roomId: '',
        messages: []
    },
    games: {
        isLoading: false,
        source: [],
        error: ''
    },
    clients: {
        isLoading: false,
        source: [],
        error: ''
    }
}