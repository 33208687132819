import { takeLatest, all, call, put } from "redux-saga/effects";
import { getGameByIdRequest } from "../../services/api/game.service";
import { getGameRoutine } from "./game.routines";

function* getGameById(action: any) {
    try {        
        const game = yield call(getGameByIdRequest, action.payload);        
        yield put(getGameRoutine.success(game));
    } catch (err) {        
        yield put(getGameRoutine.failure(err.message));
    }
}

export function* gameSaga() {
    yield all([
        takeLatest(getGameRoutine, getGameById)
    ]);
}
