import React from "react";
import { IGame } from "../../../common/types/game";

import './game-component.scss';

export interface IGameComponentProps {
    game: IGame;
    onClick: (game: IGame) => void;
}

const Game: React.FC<IGameComponentProps> = (props) => {
    const { game } = props;

    const selectGame = () => {
        props.onClick(game);
    }

    return (
        <div className="game-container" onClick={selectGame}>
            <div className="title">
                <span className="team">{game.team1Name} </span>
                vs
                <span className="team"> {game.team2Name}</span>
            </div>
            <div>
                Event id: {game.eventId}
            </div>
        </div>
    );
}

export { Game };
