import * as signalR from "@microsoft/signalr";
import { env } from "../../env";

export const connect = (): signalR.HubConnection => {
    return new signalR.HubConnectionBuilder()
        .withUrl(`${env.apiUrl}/insights`)
        .build();
}

export const disconnect = (connection: signalR.HubConnection) => {
    if (!connection) {
        throw Error('There no connection. Call connect() or check errors')
    }

    connection.stop();
    console.log('Disconnected!')
}

export const setupOn = (connection: signalR.HubConnection, onEvent: string, handler: (...args: any[]) => void) => {
    if (!connection) {
        throw Error('There no connection. Call connect() or check errors')
    }
    connection.on(onEvent, handler);
}

export const send = (connection: signalR.HubConnection, action: string, ...args: any[]) => {    
    if (!connection) {
        throw Error('There no connection. Call connect() or check errors')
    }
    connection.send(action, args);
}
