
import { initialState } from "../intialState";
import { getGameRoutine, setSelectedGameRoutine } from "./game.routines";

export const gameReducer = (state = initialState.game, action: any) => {
    switch (action.type) {
        case setSelectedGameRoutine.TRIGGER: {
            return action.payload;
        }
        case getGameRoutine.SUCCESS: {
            return action.payload;
        }
        case getGameRoutine.FAILURE: {
            console.log(action.payload);
            return state;
        }       
        default: {
            return state;
        }
    }
}