import React from 'react';

const ChatManagement: React.FC<any> = (props) => {
    return (
        <div>
            <div>Live Events</div>
            <div>Offline Events</div>
        </div>
    )
}

export { ChatManagement }