import { client } from "./client";
import { env } from "../../env";
import { IGame } from "../../common/types/game";

export const getGameByIdRequest = async (gameId: number) => {
    try {
        const response = await client.get(env.apiUrl + `/api/v1/insights/${gameId}`);
        return response.data || {} as IGame;
    }
    catch (error) {
        console.log(error);
    }
}