import * as React from 'react';
import { useState } from 'react';
import { connect } from 'react-redux';

import { Accordion } from 'semantic-ui-react';

import { TeamInsight } from '../team-insight/TeamInsight';
import { PlayerInsight } from '../player-insight/PlayerInsight';

import { sortAscending } from '../../common/helpers/sorting-insights';
import { IInsight } from '../../common/types/insights';
import { PollComponent } from '../Poll/Poll';
import { IPoll } from '../../common/types/poll';
import { ITheme } from '../../common/types/theme';


import './insights-page.scss';

interface IInsightPageProps {
	insights: IInsight[],
	liveInsights: IInsight[],
	eventId: number,
	userId: number,
	type: string,
	local: string,
	sorting: string,
	polls: IPoll[],
	activeInsightId: number,
	theme: ITheme,
}

const InsightListComponent: React.FC<IInsightPageProps> = (props) => {
	const {
		liveInsights,
		insights,
		eventId,
		local,		
		polls,
		theme,
		userId
	} = props;

	const [activeInsightId, setActiveInsightId] = useState(props.activeInsightId);
	
	const DEMO_EVENT_ID = 21026;		
	const selectedInsights = eventId === DEMO_EVENT_ID ? liveInsights : sortAscending(insights);	

	const handleClick = (id: number) => {
		const newActiveInsightId = id === activeInsightId ? -1 : id;
		setActiveInsightId(newActiveInsightId);
	};

	return (
		<div className="main-container">
			{selectedInsights.length === 0 && (
				<div>
					<span style={{ color: theme.containerTextColor }}>There no insights for this game.</span>
				</div>
			)}
			{
				selectedInsights.map((insight: IInsight) => {
					const insightProps = {
						className: "",
						insight: insight,
						local: local,
						key: insight.id,
						collapsed: insight.id !== activeInsightId,
						theme: theme
					};
					const insightDiv = props.type === 'teams'
						? <TeamInsight {...insightProps} />
						: <PlayerInsight {...insightProps} />

					const pollForInsight = polls.find(pol => pol.insightId === insight.id) as IPoll;
				
					if (!pollForInsight) {
						return (<div className="insight-poll-container" key={insight.id}> {insightDiv} </div>);
					}

					const votedOption = pollForInsight.options.find(opt => opt.votes.find(vote => vote.userId === userId));

					return (
						<Accordion key={insight.id} className="insight-poll-container">
							<Accordion.Title
								className="insight-container"
								active={activeInsightId === insight.id}
								index={insight.id}
								onClick={(_e, ind) => handleClick(Number(ind.index))}
							>
								{insightDiv}
							</Accordion.Title>

							<Accordion.Content active={activeInsightId === insight.id}>
								<PollComponent
									insight={insight}
									poll={pollForInsight}
									isVoted={Boolean(votedOption)}
									votedOption={votedOption}
									key={pollForInsight.id}
									local={local}
									theme={theme}
								/>
							</Accordion.Content>
						</Accordion>)
				})
			}
		</div>
	)
};

const mapStateToProps = (state: any, ownProps: any) => ({
	liveInsights: state.insights,
	insights: state.game.insights,
	userId: state.user!.id,
});

const InsightsPage = connect(mapStateToProps, null)(InsightListComponent);
export { InsightsPage };
