import { Formik, FormikProps } from 'formik';
import React from 'react';
import { Button, Form, Tab } from 'semantic-ui-react';
import { IClientAdminProps } from '../../../../../../../common/types/client-modal-models';

interface IAdminTabProps {
    adminInfo: IClientAdminProps;
    goToNextTab: (formPage: number, mainInfo: any) => void;
    goToPreviousTab: (formPage: number) => void;
}

const AdminTab: React.FC<IAdminTabProps> = (props) => {
    const { goToNextTab, goToPreviousTab, adminInfo } = props;

    return (
        <Tab.Pane>
            <Formik
                key={'adminForm'}
                initialValues={adminInfo}
                onSubmit={(values, { setSubmitting }) => {
                    goToNextTab(2, values);
                    setSubmitting(false);
                }}
                render={({ values, handleSubmit, handleChange }: FormikProps<IClientAdminProps>) => {
                    return <Form size="large">
                        <Form.Input
                            key={'adminEmail'}
                            label="Email"
                            fluid
                            onChange={handleChange}
                            type="email"
                            name="adminEmail"
                            placeholder="Email address"
                            value={values.adminEmail} />
                        <Form.Input
                            label="Password"
                            fluid
                            key={'passAdmin'}
                            onChange={handleChange}
                            type="password"
                            name="password"
                            placeholder="Password"
                            value={values.password} />
                        <Form.Input
                            label="Role"
                            fluid
                            key={'selectedRole'}
                            type="text"
                            name="selectedRole"
                            placeholder="Client Admin"
                            value={''} />
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Button type='button' onClick={() => goToPreviousTab(0)}>Previous</Button>
                            <Button type='button' onClick={() => handleSubmit()}>Next</Button>
                        </div>
                    </Form>

                }} />
        </Tab.Pane>
    )
}

export { AdminTab }