import { Client } from "../../common/types/client";
import { addClientRoutine, getClientsRoutine, removeClientRoutine, updateClientRoutine } from "./routines";

interface IClientState {
    source: Client[],
    error: string,
    isLoading: boolean
}

export const getClientsReducer = (state: IClientState = { isLoading: false, source: [], error: '' }, action: any) => {
    switch (action.type) {
        case getClientsRoutine.SUCCESS: {
            return {
                source: action.payload,
                isLoading: false
            }
        }
        case getClientsRoutine.FAILURE: {
            return {
                source: [],
                isLoading: false,
                error: action.payload
            }
        }
        case removeClientRoutine.SUCCESS: {
            return {
                ...state,
                isLoading: false,
                source: state.source.filter(x => x.id !== action.payload.id)
            }
        }
        case addClientRoutine.SUCCESS: {
            return {
                ...state,
                isLoading: false,
                source: [...state.source, action.payload]
            }
        }
        case updateClientRoutine.SUCCESS: {
            const { source } = state;

            const index = source.findIndex(u => u.id === action.payload.id);
            return {
                ...state,
                source: [
                    ...state.source.slice(0, index),
                    action.payload,
                    ...state.source.slice(index + 1, source.length)
                ],
                isLoading: false
            };
        }
        default: {
            return state;
        }
    }
}