import { initialState } from "../intialState";
import { setErrorRoutine } from "./routines";

export const errorHandlerReducer = (state = initialState.error, action: any) => {
    switch (action.type) {
        case setErrorRoutine.TRIGGER:
            return action.payload;
        default:
            return state;
    }
}