import * as React from 'react';
import { IMessage } from '../../common/types/message';

import './message.scss';

interface IMessageProps {
    message: IMessage;
    isOwn: boolean;
    color: string;
}

const Message: React.FC<IMessageProps> = ({ message, isOwn, color }) => {

    const className = `message_body ${isOwn ? 'own' : 'other'}`;
    return (
        <div className={className}>
            {!isOwn && <span className='message_username' style={{ color }}>{message.user.username}</span>}
            <span className='message_message'>{message.body}</span>
        </div>
    );
}

export { Message };