type Environment = {
    apiUrl: string,
    envType: string
}

const env: Environment = {
    apiUrl: process.env.REACT_APP_API_URL as string,
    envType: process.env.REACT_APP_ENV_TYPE as string
};

export { env };