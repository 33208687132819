import { initialState } from "../intialState";
import { getAllGamesRoutine, clearLiveInsightsRoutine } from "./games.routines";

export const gamesReducer = (state = initialState.games, action: any) => {

    switch (action.type) {
        case getAllGamesRoutine.TRIGGER: {
            return {
                ...state,
                isLoading: true
            }
        }
        case getAllGamesRoutine.SUCCESS: {
            return {
                isLoading: false,
                source: action.payload
            }
        }
        case clearLiveInsightsRoutine.TRIGGER: {
            return {
                ...state,
                source: state.source.filter((x: any) => !x.isLive)
            }
        }
        case getAllGamesRoutine.FAILURE: {
            return {
                ...state,
                error: action.payload
            }
        }       
        default: {
            return state;
        }
    }
}