import React, { useState } from 'react';
import { Button, Card } from 'semantic-ui-react';
import { Client } from '../../../../common/types/client';

interface IClientCardProps {
    item: Client;
    openRemoveModal: (id: number) => void;
    openUpdateModal: (item: Client) => void;
}

const ClientCard: React.FC<IClientCardProps> = ( {item, openRemoveModal, openUpdateModal } ) => {

    return (
        <Card className='client-card' key={item.name} >
        <Card.Content>
            <Card.Header>{item.name}</Card.Header>
            <Card.Meta>
                <span className='date'>Admin: {item.clientAdmin.email}</span>
            </Card.Meta>
            <Card.Description>
                Users: {item.users.length}
            </Card.Description>
            <Card.Content extra>
                <div className='card-controls' onClick={event => event.stopPropagation()}>
                    <Button color='yellow' onClick={() => openUpdateModal(item)}>
                        Update
                    </Button>
                    <Button color='red' onClick={() => openRemoveModal(item.id)}>
                        Delete
                    </Button>    
                </div>
            </Card.Content>
        </Card.Content>
    </Card>
    )
}

export { ClientCard }