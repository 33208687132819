import { client, handleError } from "./client";
import { env } from "../../env";
import { Client } from "../../common/types/client";

interface IUpdateModel {
    client: Client,
    id: number
}

export const getAllClientsRequest = async () => {
    try {
        const response = await client.get(env.apiUrl + "/api/v1/client");
        return response.data || [];
    }
    catch (error) {
        console.log(error);
    }
}

export const removeClientRequest = async (id: number) => {
    try {
        const response = await client.delete(env.apiUrl + `/api/v1/client/${id}`);
        return response.data || [];
    }
    catch (error) {
        console.log(error);
    }
}

export const addClientRequest = async (clientDto: Client) => {
    try {
        const response = await client.post(env.apiUrl + '/api/v1/client', clientDto);
        return response.data || [];
    }
    catch (error) {
        console.log(error);
        return handleError(error);
    }
}

export const updateClientRequest = async (model: IUpdateModel) => {
    try {
        const response = await client.post(env.apiUrl + `/api/v1/client/${model.id}`, model.client);
        return response.data || [];
    }
    catch (error) {
        console.log(error);
        return handleError(error);
    }
}