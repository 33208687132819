import { takeLatest, all, call, put } from "redux-saga/effects";
import { getAllGamesRoutine } from "./games.routines";
import { getAllInsightsRequest } from "../../services/api/insights.service";

function* getAllGames() {
    try {
        const insights = yield call(getAllInsightsRequest);
        yield put(getAllGamesRoutine.success(insights));
    } catch (err) {
        yield put(getAllGamesRoutine.failure(err.message));
    }
}

export function* gamesSaga() {
    yield all([
        takeLatest(getAllGamesRoutine, getAllGames)
    ]);
}